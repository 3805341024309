<template>
  <p
    v-if="isAvailableQuantityMoreThanZero(props.availableQuantity)"
    class="text-center text-xs text-blue-150"
    v-bind="$attrs"
  >
    <span class="block font-medium">{{ t('ecom_in_stock') }}:</span>
    <span>
      {{ props.availableQuantity }}
      {{
        props.measurementUnit ?? t('ecom_pck')
      }}
    </span>
  </p>
  <!-- If product is not available entirely, display proper info -->
  <p
    v-if="
      props.availabilityStatus != null
        && !props.availabilityStatus
        && hasProductExtendedDeliveryTime(props.availableQuantity)
    "
    v-bind="$attrs"
    class="text-center text-xs font-medium text-blue-150"
  >
    {{ t('ecom_product_available_on_request') }}
  </p>
</template>

<script setup lang="ts">
import { hasProductExtendedDeliveryTime } from '@ecom/composables/checkout/utils'
import { useCartProductData } from '@ecom/composables/checkout/useCartProductData/useCartProductData'
import type { M2ProductItemAvailabilityStockInfoProps } from './M2ProductItemAvailabilityStockInfo.types'

const props = defineProps<M2ProductItemAvailabilityStockInfoProps>()

const { isAvailableQuantityMoreThanZero } = useCartProductData()

const { t } = useI18n()
</script>
